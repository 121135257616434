import axios from "axios";
import { ReactElement, useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { SearchResultModel } from "../../models/SearchResultModel";
import { app } from "../..";
import style from "./userexchanges.module.scss";
import moment from "moment";
import classNames from "classnames";
import { AttachmentType } from "../../models/Enums";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LocalizationContext } from "../../interfaces/AppContext";
import { IsUndefinedOrNull } from "../../misc/Utilities";

export const UserExchanges = (): ReactElement => {

    const { personid } = useParams();

    const { localization } = useContext(LocalizationContext);

    const [exchanges, setExchanges] = useState<SearchResultModel[]>([]);

    const [name, setName] = useState("");

    const nav = useNavigate();

    useEffect(() => {

        const response = axios.get(`${app.apiBasePath}/search/exchanges/${personid}`)
        response.then(result => {
            setExchanges(result.data);

            if (Array.isArray(result.data) && result.data.length > 0) {
                const first = result.data[0] as SearchResultModel;
                setName(first.postedByName);
            }

        });


    }, [personid]);

    return (
        <div className={style.container}>
            <div className={style.f}>
                <a onClick={() => nav(-1)}>
                    <FontAwesomeIcon icon={"chevron-left"} />
                </a>
                <h3>Echanges : {name}</h3>
            </div>
            <div className={style.collection}>
                {
                    exchanges.map(e => {
                        return (

                            <Link key={e.id} className={classNames(style.exchange, e.exchangeType === "demande" ? style.demande : null)} to={`/exchanges/${e.id}`}>

                                <div>
                                    <h4>
                                        {e.exchange.title}
                                        {
                                            e.exchangeType === "demande" &&
                                            <span>( demande )</span>
                                        }

                                    </h4>
                                    <div className={style.pb}>
                                        <strong>{localization["exchangePosted"]}</strong>
                                        <span>{moment.utc(e.exchange.publishedOn).local().fromNow()}</span>
                                    </div>
                                    <div>
                                        {
                                            typeof e.file !== "undefined" &&
                                            e.file !== null &&
                                            e.file.attachmentType === AttachmentType.Image &&
                                            <img src={`${app.apiBasePath}/assets/image/thumb/${e.file.fileId}`} alt={"missing image"} />
                                        }
                                    </div>
                                    <div className={style.sub}>
                                        {
                                            !IsUndefinedOrNull(e.exchange.valueInPoints) &&
                                            <span className={classNames(style.tag, style.points)}>{e.exchange.valueInPoints} points</span>
                                        }
                                        <span className={classNames(style.tag, style.distance)}>~{Math.round((e.distanceMeters / 1000) * 10) / 10} km</span>
                                    </div>
                                </div>
                                <FontAwesomeIcon icon="chevron-right" />
                            </Link>
                        )
                    })
                }
            </div>

        </div>
    );
}