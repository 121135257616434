import { FC, ReactElement, useContext, useEffect, useRef, useState } from "react";
import { FilterView } from "../Controls/FilterView";
import { Slider } from "@mui/material";
import Select from "react-select";
import { ExchangeOptions, ExchangeOrdering } from "../../misc/SelectOptions";
import { SelectStylesFilter } from "../../misc/Constants";
import { Option, OptionTypeDescription } from '../SelectExtension/SelectExtension';
import { Category } from "../../models/Category";
import { AppContext, LocalizationContext } from "../../interfaces/AppContext";
import { IsUndefinedOrNull, getHashCode, isEmptyCollection, objecToQueryString } from "../../misc/Utilities";
import { OptionTypeDescriptionFilter } from '../../misc/ReactSelectHelper';
import classNames from "classnames";
import { Popup } from "../Popup/Popup";
import { GroupSelector } from "../Controls/GroupSelector";
import { Baloon } from "../Controls/Baloon";
import { OrderType, SearchOptions } from "../../models/SearchOptions";
import { OptionType, OptionTypeGeneric } from "../../interfaces/OptionType";
import { useLocalStorage } from "../../hooks/UseLocalStorage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SearchSettings } from "../Controls/SearchSettings";
import { homeStore, RadiusValues } from "../../store/homeStore";
import style from "./advanced-search.module.scss";
import { Checkbox } from "../Checkbox";

export enum PopupType {
    Groups,
    Settings
}

export interface AdvancedSearchProps {
    categories: Category[];
    radiusAvailable: boolean;
}

export const AdvancedSearch: FC<AdvancedSearchProps> = ({ radiusAvailable, categories }): ReactElement => {

    const { categoryId, exchangeType, orderBy, locationIds, radiusControlPosition, queryText, includeMyExchanges, includeResultsOffgidExchanges, IsPointsOnly, update } = homeStore();

    const { localization } = useContext(LocalizationContext);
    const { groups, localGroups } = useContext(AppContext);
    const { saveToStorage } = useLocalStorage();

    const [selectedGroups, setSelectedGroups] = useState(isEmptyCollection(locationIds) ? [] : locationIds);
    const [orderOption, setOrderOption] = useState<OptionTypeGeneric<OrderType>>(ExchangeOrdering.find(p => p.value === orderBy));
    const [tempRadius, setTempRadius] = useState(radiusAvailable ? radiusControlPosition : 0);
    const [popupType, setPopupType] = useState<PopupType>(null);
    const [tempQuery, setTempQuery] = useState(queryText);
    const [query, setQuery] = useState(queryText);
    const [showOffGrid, setShowOffGrid] = useState(includeResultsOffgidExchanges);


    const disanceValues = radiusAvailable ? RadiusValues : [0];

    let initExchageType = ExchangeOptions.find(p => p.value === exchangeType);

    if (IsUndefinedOrNull(initExchageType)) {
        initExchageType = ExchangeOptions.find(p => p.value === "*");
    }

    const [exchangeTypeOption, setExchangeTypeOption] = useState<OptionType>(initExchageType);

    const allCategory: Category = {
        id: "*",
        labelKey: "allCategories"
    };

    const categoryOptions = [allCategory, ...categories];

    const categoryOptionTypes: OptionTypeDescription[] = categoryOptions.map(c => {
        return {
            value: c.id,
            label: localization[c.labelKey],
            description: localization[c.descriptionKey] ?? "",
            example: localization[c.exampleKey] ?? ""
        }
    });

    let cat = categoryOptionTypes.find(c => c.value === categoryId);

    if (IsUndefinedOrNull(cat)) {
        cat = categoryOptionTypes[0];
    }

    const [selectedCategory, setSelectedCategory] = useState<OptionTypeDescription>(cat);

    const markers = disanceValues.map((val, idx) => {
        return {
            value: idx
        };
    });

    const handleFilterClick = (type: PopupType): void => {
        typeof onFilterClick === "function" && onFilterClick(type);
    };

    const formatSliderLabel = (val: number): number | string | ReactElement => {
        if (val === markers.length - 1) {
            return <span style={{ fontSize: "1.4em" }}>∞</span>;
        }
        else return <span>{disanceValues[val]} km</span>;
    };

    const onFilterClick = (type: PopupType): void => {
        setPopupType(type);
    };

    const onCategoryChange = (opt: OptionTypeDescription): void => {
        setSelectedCategory(opt as any);
        update("categoryId", opt.value as string);
    };

    const onPointsOnlyChange = (): void => {
        update("IsPointsOnly", !IsPointsOnly)
    }

    const onExchangeOrderChange = (opt: OptionTypeGeneric<OrderType>): void => {
        setOrderOption(opt);
        update("orderBy", opt.value as OrderType);
    };

    const onExchangeTypeChange = (opt: OptionType): void => {
        setExchangeTypeOption(opt);
        update("exchangeType", opt.value as string);
    };


    const listSelectedGroupNames = (): JSX.Element => {
        const names = localGroups.filter(g => selectedGroups.some(p => p === g.id));

        return <>{
            names.map(n => {
                return <div key={n.id}><span>{n.name}</span></div >
            })
        }</>
    };

    const debounceRef = useRef(null);
    const queryTextChanged = (text: string): void => {
        window.clearTimeout(debounceRef.current);
        setTempQuery(text);
        debounceRef.current = window.setTimeout(() => {
            setQuery(text);
            update("queryText", text);
        }, 400);
    };

    let groupSelectionLabel = selectedGroups.length === 1 ? localGroups.find(g => g.id === selectedGroups[0]).name : `${selectedGroups.length} groupes`;
    if (selectedGroups.length === localGroups.length) {
        groupSelectionLabel = localization["allGroups"];
    }

    if (selectedGroups.sort().join(',') === groups.map(p => p.id).sort().join(',')) {
        groupSelectionLabel = localization["myGroups"];
    }

    const onDistanceChange = (val: number): void => {
        update("radiusControlPosition", val as number);
    }

    const invokeSearch = (): void => {
        const model: SearchOptions = {
            orderBy: orderOption?.value as OrderType,
            exchangeType: exchangeTypeOption?.value as string,
            radiusControlPosition: radiusControlPosition,
            locationIds: selectedGroups,
            queryText: query,
            categoryId: selectedCategory.value as string,
            includeResultsOffgidExchanges: showOffGrid,
            includeMyExchanges: includeMyExchanges,
            IsPointsOnly: IsPointsOnly
        };

        const str = objecToQueryString(model);
        const code = getHashCode(str);
        update("page", 1);
        update("searchHashCode", code);
    };


    useEffect(() => {
        // write to storage
        const model: SearchOptions = {
            orderBy: orderOption?.value as OrderType,
            exchangeType: exchangeTypeOption?.value as string,
            radiusControlPosition: radiusControlPosition,
            locationIds: selectedGroups,
            queryText: query,
            categoryId: selectedCategory.value as string,
            includeResultsOffgidExchanges: showOffGrid,
            includeMyExchanges: includeMyExchanges,
            IsPointsOnly: IsPointsOnly
        };
        saveToStorage("search-options", model);

        const str = objecToQueryString(model);
        const code = getHashCode(str);
        update("page", 1);
        update("searchHashCode", code);

    }, [orderOption, exchangeTypeOption, radiusControlPosition, selectedGroups, query, selectedCategory, showOffGrid, includeMyExchanges, IsPointsOnly]);

    const getPopupTitleFromType = (type: PopupType): string => {
        switch (type) {
            case PopupType.Groups:
                return "Sélectionnez les noms de groupes à filtrer";
            case PopupType.Settings:
                return "Paramètres de recherche";
            default:
                return "";
        }
    }

    return (

        <div className={style.main}>
            {
                popupType !== null &&
                <Popup title={getPopupTitleFromType(popupType)} onClose={() => { setPopupType(null) }}>
                    <>
                        {
                            popupType === PopupType.Groups &&
                            <GroupSelector
                                selected={selectedGroups}
                                onSelectionChange={(selection: Array<string>) => {
                                    setSelectedGroups(selection);
                                    update("locationIds", selection);
                                    setPopupType(null);
                                }} />
                        }
                        {
                            popupType === PopupType.Settings &&
                            <SearchSettings
                                includedMyExchangesInResults={includeMyExchanges}
                                includeResultsFromIndependentUsers={showOffGrid}
                                onSettingsChange={(settings) => {
                                    update("includeMyExchanges", settings.includedMyExchangesInResults);
                                    setShowOffGrid(settings.includeResultsFromIndependentUsers);
                                    setPopupType(null);
                                }}
                            />
                        }
                    </>
                </Popup>
            }

            <div className={style.filters}>
                <div className={style.f1}>
                    <Baloon text={listSelectedGroupNames()}>
                        <FilterView onClick={() => handleFilterClick(PopupType.Groups)} label={groupSelectionLabel} />
                    </Baloon>
                </div>

                <div className={style.f2}>
                    <Select
                        menuPortalTarget={document.body}
                        value={exchangeTypeOption}
                        styles={SelectStylesFilter}
                        onChange={onExchangeTypeChange}
                        options={ExchangeOptions} />
                </div>
                <div className={style.f3}>
                    <Select
                        menuPortalTarget={document.body}
                        components={{ Option }}
                        value={selectedCategory}
                        options={categoryOptionTypes}
                        placeholder={localization["OfferCategoryLabel"]}
                        filterOption={OptionTypeDescriptionFilter}
                        onChange={c => onCategoryChange(c as any)}
                        styles={{ ...SelectStylesFilter }} />
                </div>

                <div className={style.onlypts}>
                    <Checkbox
                        label="100% points"
                        checked={IsPointsOnly}
                        onCheckChange={() => onPointsOnlyChange()} />
                </div>

                <div className={classNames(style.radiuscontrol, style.f4)}>
                    <div>
                        <Slider
                            className={style.rangeslider}
                            min={0}
                            aria-label="distance selector"
                            max={markers.length - 1}
                            value={tempRadius}
                            marks={markers}
                            step={null}
                            valueLabelDisplay={"off"}
                            valueLabelFormat={formatSliderLabel}
                            onChange={(e, val) => setTempRadius(val as number)}
                            onChangeCommitted={(e, val) => onDistanceChange(val as number)}
                        />
                    </div>
                    {

                        disanceValues[tempRadius] === 0 &&
                        <span className={style.inf}>∞</span>
                    }
                    {
                        disanceValues[tempRadius] !== 0 &&
                        <span>{disanceValues[tempRadius]} km</span>
                    }
                </div>

                <div className={style.f5}>
                    <div className={style.textinput}>
                        <input type="text" placeholder={localization["keyWords"]} value={tempQuery} onChange={e => queryTextChanged(e.target.value)} />
                        <button className={tempQuery.length === 0 ? style.hide : null} onClick={e => queryTextChanged("")}>
                            <FontAwesomeIcon icon={"x"} />
                        </button>
                    </div>
                </div>

                <div className={style.f6}>
                    <div className={style.searchctrl}>
                        <button onClick={() => invokeSearch()} className="btn action">
                            <FontAwesomeIcon icon={"search"} />
                        </button>

                        <div className={style.separator}></div>

                        <div className={style.ordergroup}>
                            <FontAwesomeIcon icon="arrow-down-wide-short" />
                            <Select
                                menuPortalTarget={document.body}
                                value={orderOption}
                                onChange={onExchangeOrderChange} styles={SelectStylesFilter} options={ExchangeOrdering} />
                        </div>

                        <button onClick={() => handleFilterClick(PopupType.Settings)} className="btn signup">
                            <FontAwesomeIcon icon={"gear"} />
                        </button>
                    </div>
                </div>
            </div>
        </div>

    );
}