import React, { useContext, useEffect, useMemo, useState } from 'react';
import { AppContext, LocalizationContext } from "../../interfaces/AppContext";
import Select, { MultiValue } from 'react-select';
import Editor from 'ckeditor5-custom-build';
import { LocationOptionType, OptionType, OptionTypeGeneric } from '../../interfaces/OptionType';
import { AttachmentType, ExchangeState, GroupPrivileges } from '../../models/Enums';
import axios from 'axios';
import { CKConfig, PubSubTopic, ReactSelectStylesCompactOptions, SelectStyleFix } from '../../misc/Constants';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { Category } from '../../models/Category';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router';
import { PrivacySettings } from '../../models/PrivacySettings';
import { ContactPrivacyOptions } from './ContactPrivacyOptions';
import { IsMobile, IsNullOrWhiteSpace, IsUndefinedOrNull, StringIsNullOrEmpty, ValueOrDefault, buildOtherLocations, getGroupsFromAvailableLocations } from '../../misc/Utilities';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ErrorArg, useForm, ValidationMethods } from '../../hooks/UseForm';
import { Message } from '../../misc/EventMessages';
import { ExchangeDraft } from '../../models/ExchangeDraft';
import { Exchange } from '../../models/Exchange';
import { MultiRadio, MultiRadioOption } from '../../components/MultiRadio';
import { GGLMap, GGLMarker } from '../../components/GGLMap';
import { GeoLocation } from '../../models/GeoLocation';
import { Link } from 'react-router-dom';
import { app } from '../..';
import classNames from 'classnames';
import { InputWarning } from '../../components/InputWarning';

import { Attachment } from '../../models/Attachment';
import { Thumbnails } from '../../components/Thumbnails/Thumbnails';

import { Option, OptionTypeDescription } from "../../components/SelectExtension/SelectExtension";
import { OptionTypeDescriptionFilter } from '../../misc/ReactSelectHelper';
import { Radio } from '../../components/Radio';
import style from './exchangeform.module.scss';
import { ExchangeType } from '../../models/ExchangeType';
import { Checkbox } from '../../components/Checkbox';

interface ILenghtRemainDisplay {
    min: number;
    max: number;
    current: number;
}

const LenghtRemainDisplay = (props: ILenghtRemainDisplay): JSX.Element => {



    const { localization } = useContext(LocalizationContext);

    if (props.current - props.min < 0) {
        return <span><span className="tiny-info warn">{localization["CharsRequired"]}:<span>{props.current - props.min}</span>
        </span></span>
    }
    else {
        return <span className="tiny-info">chars remain:
            <span>{props.max - props.current}</span>
        </span>
    }
};

export interface ExchangeFormProps {
    title?: string;
    exchangeType?: string;
    correlationId?: string;
    categoryId?: string;
    subCategoryId?: string;
    subSubCategoryId?: string;
    webUrl?: string;
    shareEmailContact?: boolean;
    shareOtherContact?: boolean;
    sharePrimaryContact?: boolean;
    shareSecondaryContact?: boolean;
    otherContact?: string;
    selectedRegion?: string;
    description?: string;
    categories: Category[];
    privacySettings?: PrivacySettings;
    groupIds?: string[];
    location?: GeoLocation;
    usingProfileLocation?: boolean;
    state?: ExchangeState;
    attachments?: Attachment[];
    isPublic?: boolean;
    valueInPoints?: number;
    isPointsOnly?: boolean;
}



interface ExchangeFormModel {
    groupIds: OptionType[];
    groupOptions: OptionType[];
    title: string;
    category: OptionTypeDescription;
    sharePrimaryContact: boolean;
    shareSecondaryContact: boolean;
    shareEmailContact: boolean;
    shareOtherContact: boolean;
    otherContact: string;
    description: string;
    webUrl: string;
    useProfileLocation: boolean;
    locationLongitude?: number;

    locationLatitude?: number;
    isPublic: boolean;
    exchangeType: OptionTypeGeneric<ExchangeType>;
    exchangeTypeOptions: OptionTypeGeneric<ExchangeType>[];
    valueInPoints?: number;
    isPointsOnly: boolean;
}

enum OfferValidators {
    region,
    title,
    category,
    contact,
    location,
    description,
    default
}


const ExchangeForm = (props: ExchangeFormProps): JSX.Element => {

    const { exchangeid } = useParams();

    let sectionIndex = 0; // used for setting numerical steps

    const nav = useNavigate();

    const { localization } = useContext(LocalizationContext);

    const { groups, person } = useContext(AppContext);

    const groupOptions: Array<OptionType> = groups.map(g => {
        return {
            value: g.id,
            label: g.name
        } as OptionType
    });

    const isMobile = useMemo(() => IsMobile(), []);

    let locationLat = null;
    let locationLgt = null;

    if (!props.usingProfileLocation) {
        locationLat = IsUndefinedOrNull(props.location) ? null : props.location.coordinates[1];
        locationLgt = IsUndefinedOrNull(props.location) ? null : props.location.coordinates[0];
    }

    const eTypeOptios: OptionTypeGeneric<ExchangeType>[] = [
        {
            label: localization["offering"],
            value: "offer"
        },
        {
            label: localization["lookingfor"],
            value: "demande"
        }
    ];

    let initEType = eTypeOptios.find(t => t.value === props.exchangeType);
    if (IsUndefinedOrNull(initEType)) {
        initEType = eTypeOptios[0];
    }

    let selectedGroupOption: Array<OptionType> = groupOptions;

    if (typeof props.groupIds !== "undefined" && props.groupIds !== null) {
        selectedGroupOption = groupOptions.filter(g => props.groupIds.some(p => p === g.value));
    }

    const initFormState: ExchangeFormModel = {

        exchangeTypeOptions: eTypeOptios,
        exchangeType: initEType,
        isPublic: ValueOrDefault(props.isPublic, true),
        groupIds: selectedGroupOption,
        groupOptions: groupOptions,
        title: ValueOrDefault(props.title, ""),
        category: null,
        sharePrimaryContact: ValueOrDefault(props.privacySettings?.displayPrimaryContact, false),
        shareEmailContact: ValueOrDefault(props.privacySettings?.displayEmailAddress, false),
        shareOtherContact: ValueOrDefault(props.privacySettings?.displayOtherContact, false),
        shareSecondaryContact: ValueOrDefault(props.privacySettings?.displaySecondaryContact, false),
        otherContact: ValueOrDefault(props.otherContact, ""),
        description: ValueOrDefault(props.description, ""),
        webUrl: ValueOrDefault(props.webUrl, ""),

        locationLatitude: ValueOrDefault(locationLat, null),
        locationLongitude: ValueOrDefault(locationLgt, null),

        useProfileLocation: ValueOrDefault(props.usingProfileLocation, false),
        valueInPoints: ValueOrDefault(props.valueInPoints, null),
        isPointsOnly: ValueOrDefault(props.isPointsOnly, false)
    };

    let locationLabel = IsNullOrWhiteSpace(person.locationLabel) ?
        `${person.locationLongitude} ${person.locationLatitude}` : person.locationLabel;

    const locationOptions = [
        {
            label: locationLabel,
            value: 0
        },
        {
            label: localization["customLocationLabel"],
            value: 1
        }
    ];


    const [minTitleLenght] = useState(5);
    const [maxTitleLenght] = useState(70);


    const baseCategories: OptionTypeDescription[] = props.categories.map(c => {
        return {
            value: c.id,
            label: localization[c.labelKey],
            description: localization[c.descriptionKey],
            example: localization[c.exampleKey],
        }
    });

    if (!IsUndefinedOrNull(props.categoryId)) {

        const selectedCategory = baseCategories.find(c => c.value === props.categoryId);

        if (selectedCategory) {
            initFormState.category = selectedCategory;
        }
    }

    const [categoryOptions, setCategoryOptions] = useState<OptionTypeDescription[]>(baseCategories);

    const [isDescriptionChanged, setIsDescriptionChanged] = useState(false);

    let selectedLocationOption: MultiRadioOption = null;

    if (props.usingProfileLocation) {
        selectedLocationOption = locationOptions[0];
    }
    else {
        if (!IsUndefinedOrNull(props.location)) {
            selectedLocationOption = locationOptions[1];
        }
    }

    const [locationOption, setLocationOption] = useState(selectedLocationOption);

    const [mapZoom, setMapZoom] = useState(5);

    const profileHasLocation = !IsUndefinedOrNull(person.locationLatitude) && !IsUndefinedOrNull(person.locationLongitude);


    const validations: ValidationMethods<ExchangeFormModel>[] = [
        {
            validator: OfferValidators.region,
            use: (model: ExchangeFormModel): ErrorArg => {
                return (model.groupIds === null || model.groupIds.length < 1) && model.isPublic === false ?
                    { isValid: false, message: "Selectionnez la région" } :
                    { isValid: true, message: "" }
            }
        },
        {
            validator: OfferValidators.title,
            use: (model: ExchangeFormModel): ErrorArg => {

                const valid = !IsNullOrWhiteSpace(model.title) && model.title.trim().length > 4;
                return valid ?
                    { isValid: true, message: "" } :
                    { isValid: false, message: localization["OfferTitleMissingErrorMsg"] };

            }
        },
        {
            validator: OfferValidators.category,
            use: (model: ExchangeFormModel): ErrorArg => {

                const valid = !IsUndefinedOrNull(model.category) && model.category.value !== "";
                return valid ?
                    { isValid: true, message: "" } :
                    { isValid: false, message: localization["categorySelectPlaceholder"] };
            }
        },
        {
            validator: OfferValidators.contact,
            use: (model: ExchangeFormModel): ErrorArg => {

                const valid = model.sharePrimaryContact ||
                    model.shareSecondaryContact ||
                    model.shareEmailContact ||
                    (model.shareOtherContact && !IsNullOrWhiteSpace(model.otherContact));


                return valid ?
                    { isValid: true, message: "" } :
                    { isValid: false, message: localization["RequireContactValidationError"] };
            }
        },
        {
            validator: OfferValidators.description,
            use: (model: ExchangeFormModel): ErrorArg => {
                const valid = !IsNullOrWhiteSpace(model.description);
                return valid ?
                    { isValid: true, message: "" } :
                    { isValid: false, message: localization["descriptionRequiredMessage"] };
            }
        },
        {
            validator: OfferValidators.location,
            use: (model: ExchangeFormModel): ErrorArg => {

                if (model.useProfileLocation) {
                    return { isValid: true, message: "" };
                }

                const valid = !IsUndefinedOrNull(model.locationLatitude) &&
                    !isNaN(model.locationLatitude) &&
                    !IsUndefinedOrNull(model.locationLongitude) &&
                    !isNaN(model.locationLongitude);

                return valid ? { isValid: true, message: "" } : { isValid: false, message: "select location" };
            }
        }
    ];

    const { values, isValid, changeHandler, valueSetter, error, touched, touchReset } = useForm<ExchangeFormModel, OfferValidators>(initFormState, validations);

    const onPrimaryCategoryChange = (option: any): void => {
        valueSetter({ name: "category", value: option });
    };

    const onContactCheckChange = (control: string, event): void => {

        switch (control) {
            case 'sharePrimaryContact':
                valueSetter({ name: control, value: !values.sharePrimaryContact });
                break;
            case 'shareSecondaryContact':
                valueSetter({ name: control, value: !values.shareSecondaryContact });
                break;
            case 'shareEmailContact':
                valueSetter({ name: control, value: !values.shareEmailContact });
                break;
            case 'shareOtherContact':
                valueSetter({ name: control, value: !values.shareOtherContact });
                break;
            default:
                break
        }
    };

    const doSaveExchange = async (isDraft: boolean): Promise<Exchange> => {

        const draftModel: ExchangeDraft = {
            exchangeType: values.exchangeType?.value,
            exchangeId: exchangeid,
            title: values.title,
            description: values.description,
            groupIds: values.groupIds.map(g => { return g.value as string }),
            categoryId: values.category?.value as string,

            privacySettings: {
                sharePrimaryContact: values.sharePrimaryContact,
                shareSecondaryContact: values.shareSecondaryContact,
                shareEmailContact: values.shareEmailContact,
                shareOtherContact: values.shareOtherContact
            },
            webUrl: values.webUrl,
            otherContactText: values.otherContact,
            state: ExchangeState.Draft,

            locationLongitude: values.locationLongitude,
            locationLatitude: values.locationLatitude,
            usingProfileLocation: values.useProfileLocation,
            isPublic: values.isPublic,
            valueInPoints: values.valueInPoints,
            isPointsOnly: values.isPointsOnly
        };

        const path = isDraft ? `${app.apiBasePath}/exchange/draft/publish` : `${app.apiBasePath}/exchange/update/published`;

        const result = await axios.post(path, draftModel);
        return result.data as Exchange;
    };

    const updatePublishedOffer = async (): Promise<string> => {

        const ex = await doSaveExchange(false);

        PubSub.publish(PubSubTopic.Action, {
            id: Message.UpdateInboxExchanges,
            data: ex
        });

        touchReset();
        return ex.id;
    };

    const saveOfferDraft = async (): Promise<string> => {

        const ex = await doSaveExchange(true);

        PubSub.publish(PubSubTopic.Action, {
            id: Message.UpdateInboxExchanges,
            data: ex
        });

        touchReset();
        return ex.id;
    };

    const onSaveOfferDraft = async (): Promise<void> => {
        const exchangeid = await saveOfferDraft();
        if (IsUndefinedOrNull(props.state)) {
            nav(exchangeid);
        }
    };

    const saveExistingDraftChanges = async (): Promise<void> => {
        const exchange = await doSaveExchange(true);
        touchReset();
    };

    const publishOffer = async (): Promise<void> => {

        const dirty = isDirty();

        let eid = exchangeid;
        let exchange: Exchange = null;

        if (dirty) {
            exchange = await doSaveExchange(true);
            eid = exchange.id;
            touchReset();
        }

        await axios.post(`${app.apiBasePath}/exchange/setpublished`, {
            exchangeid: eid
        });

        if (exchange !== null) {
            exchange.state = ExchangeState.Published;
            PubSub.publish(PubSubTopic.Action, {
                id: Message.UpdateInboxExchanges,
                data: exchange
            });
        }
        else {
            PubSub.publish(PubSubTopic.Action, {
                id: Message.UpdateExchangeStatePublished,
                data: eid
            });
        }

        window.setTimeout(() => {
            nav(`/exchanges/${eid}`);
        }, 0);
    };

    const unpublishOffer = async (): Promise<void> => {
        if (window.confirm(localization["confirmUnpublish"])) {
            PubSub.publish(PubSubTopic.Action, {
                id: Message.UnpublishExchange,
                data: {
                    exchangeId: exchangeid
                }
            });
        }
    };

    const deleteExchange = async (): Promise<void> => {
        if (window.confirm(`${localization["confimDelete"]}: ${values.title}`)) {
            PubSub.publish(PubSubTopic.Action, {
                id: Message.DeleteDraft,
                data: {
                    id: exchangeid
                }
            });
            nav("/");
        }
    };

    const saveOfferDraftAndRedirect = async (): Promise<void> => {
        const exchangeid = await saveOfferDraft();
        nav(`/post/exchange/${exchangeid}/files`);
    }

    const onGoToImages = async (): Promise<void> => {
        const isNew = IsUndefinedOrNull(props.state);

        if (isNew) {
            await saveOfferDraftAndRedirect();
        }
        else {
            const dirty = isDirty();


            if (dirty) {
                await updatePublishedOffer();
                nav(`/post/exchange/${exchangeid}/files`);
            }
            else {
                nav(`/post/exchange/${exchangeid}/files`);
            }
        }


    };

    const onMapClick = (point: google.maps.LatLng, zoom: number): void => {
        setMapZoom(zoom);

        const lat = point.lat();
        const lng = point.lng();

        const values = [
            { name: "locationLatitude", value: lat },
            { name: "locationLongitude", value: lng }
        ];
        valueSetter(values);
    };

    const onSetLocationOption = (opt: MultiRadioOption): void => {
        valueSetter({ name: "useProfileLocation", value: opt.value === 0 });
        setLocationOption(opt);
    };

    const saveDescription = (): void => {
        if (StringIsNullOrEmpty(exchangeid))
            return;

        const rq = axios.post(`${app.apiBasePath}/exchange/updatedescription`, {
            exchangeid: exchangeid,
            descriptionText: values.description
        });

        rq.then(r => {
            setIsDescriptionChanged(false);
        });
    };

    const isDirty = (): boolean => {
        const r: boolean[] = Object.getOwnPropertyNames(touched).map(name => {
            const t = touched[name];
            return touched[name];
        });
        return r.some(p => p === true);
    };

    const onExchangeReachLocationChange = (opt: MultiValue<OptionType>): void => {
        valueSetter({
            name: "groupIds",
            value: opt
        });
    };

    const onRadioForRangeChange = (option: boolean | string): void => {
        if (typeof option === "string") {
            const selectedOption = values.groupOptions.find(g => g.value === option);
            if (selectedOption) {
                valueSetter([{ name: "groupIds", value: [selectedOption] }, { name: "isPublic", value: false }]);
            }
        }
        else {
            if (option === true) {
                valueSetter([{ name: "isPublic", value: true }, { name: "groupIds", value: [] }]);
            }
            else {
                valueSetter({ name: "isPublic", value: false });
            }
        }
    };

    return (
        <section>
            <div className={classNames(style.form, "form", "offer", values.exchangeType?.value === "demande" ? style.demande : null)}>
                <div className={"row"}>
                    {
                        props.state !== ExchangeState.Published &&
                        values.exchangeType?.value === "offer" &&
                        <h1 className="right">{localization["OfferPostHeading"]}</h1>
                    }
                    {
                        props.state !== ExchangeState.Published &&
                        values.exchangeType?.value === "demande" &&
                        <h1 className="right">{localization["DemandePostHeading"]}</h1>
                    }
                    {
                        props.state === ExchangeState.Published &&
                        <h1 className="right">{localization["EditOfferPostHeading"]}</h1>
                    }

                </div>

                <div className={style.formsizer}>
                    <section>
                        <div className="row-info">
                            <aside>
                                <div><span>{++sectionIndex}</span></div>
                            </aside>
                            <div className="section-container">
                                <div className="row">
                                    <label>{localization["exchangeType"]}</label>
                                    <Select
                                        isMulti={false}
                                        value={values.exchangeType}
                                        options={values.exchangeTypeOptions}
                                        onChange={opt => valueSetter({ name: "exchangeType", value: opt })}
                                    />
                                </div>
                            </div>
                        </div>
                    </section>

                    <section>
                        <div className="row-info">
                            <aside className={error(OfferValidators.region).isValid ? "" : style.warn}>
                                <div><span>{++sectionIndex}</span></div>
                            </aside>
                            <div className="section-container">
                                <div className="row">
                                    <label>{localization["selectVisibility"]}</label>

                                    <div className={style.radios}>
                                        {
                                            values.groupOptions.length === 1 &&
                                            <Radio
                                                group="range"
                                                label={values.groupOptions[0].label}
                                                value={values.groupOptions[0].value}
                                                currentValue={values.groupIds[0]?.value}
                                                onChange={() => onRadioForRangeChange(values.groupOptions[0].value as string)} />
                                        }
                                        {
                                            values.groupOptions.length > 1 &&
                                            <Radio
                                                group="range"
                                                label="mes groupes"
                                                value={0}
                                                currentValue={values.isPublic ? 1 : 0}
                                                onChange={() => onRadioForRangeChange(false)} />
                                        }

                                        <Radio
                                            group="range"
                                            label="tous les groupes"
                                            value={1}
                                            currentValue={values.isPublic ? 1 : 0}
                                            onChange={() => onRadioForRangeChange(true)} />
                                    </div>
                                    {
                                        !values.isPublic &&
                                        values.groupOptions.length > 1 &&
                                        <Select
                                            isMulti={true}
                                            value={values.groupIds}
                                            options={values.groupOptions}
                                            onChange={onExchangeReachLocationChange}
                                        />
                                    }

                                    {
                                        !error(OfferValidators.region).isValid &&
                                        <InputWarning messageKey={error(OfferValidators.region).message} />
                                    }
                                </div>
                            </div>
                        </div>
                    </section>

                    <section>
                        <div className="row-info">
                            <aside className={error(OfferValidators.title).isValid ? "" : style.warn}>
                                <div><span>{++sectionIndex}</span></div>
                            </aside>
                            <div className="section-container">

                                <div className="row">
                                    <div className="flex w">
                                        <label>{localization["OfferTitleLabel"]}</label>

                                        <LenghtRemainDisplay
                                            min={minTitleLenght}
                                            max={maxTitleLenght}
                                            current={values.title.length} />

                                    </div>
                                    <input
                                        type="text"
                                        name="title"
                                        onChange={changeHandler}
                                        maxLength={maxTitleLenght}
                                        value={values.title}
                                        className={error(OfferValidators.title).isValid ? "form-control" : "form-control warn"}
                                        placeholder={localization["OfferTitlePlaceholder"]} />
                                    {
                                        !error(OfferValidators.title).isValid &&
                                        <InputWarning messageKey={error(OfferValidators.title).message} />
                                    }

                                </div>
                            </div>
                        </div>
                    </section>

                    <section>
                        <div className="row-info">
                            <aside className={error(OfferValidators.category).isValid ? "" : style.warn}>
                                <div><span>{++sectionIndex}</span></div>
                            </aside>
                            <div className="section-container">
                                <div className="row">
                                    <label>{localization["OfferCategoryLabel"]}</label>


                                    <Select
                                        components={{ Option }}
                                        isSearchable={!isMobile}
                                        styles={{ ...SelectStyleFix, ...ReactSelectStylesCompactOptions }}
                                        value={values.category}
                                        onChange={option => onPrimaryCategoryChange(option)}
                                        options={categoryOptions}
                                        filterOption={OptionTypeDescriptionFilter}
                                        placeholder={localization["categorySelectPlaceholder"]} />

                                    {
                                        !error(OfferValidators.category).isValid &&
                                        <InputWarning messageKey={error(OfferValidators.category).message} />
                                    }

                                    {
                                        values.category !== null &&
                                        <div className={style.categoryInfo}>
                                            <FontAwesomeIcon icon={"circle-info"} />
                                            <div>
                                                <p>{values.category.description}</p>
                                                <div>
                                                    <span>
                                                        {values.category.example}
                                                    </span>
                                                </div>
                                            </div>

                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </section>


                    <section>
                        <div className="row-info">
                            <aside>
                                <div><span>{++sectionIndex}</span></div>
                            </aside>
                            <div className="section-container">
                                <div className="row">
                                    <label>{localization["valueInPoints"]}</label>
                                    <div style={{ width: "100px" }}>
                                        <input
                                            type="number"
                                            min={0}
                                            className="form-control"
                                            value={values.valueInPoints}
                                            onChange={e => valueSetter({ name: "valueInPoints", value: parseInt(e.target.value) })}
                                        />
                                    </div>
                                    {
                                        values.valueInPoints > 0 &&
                                        <div style={{marginTop:"10px"}}>
                                            <Checkbox
                                                label={localization["allInPoints"]}
                                                onCheckChange={() => valueSetter({ name: "isPointsOnly", value: !values.isPointsOnly })}
                                                checked={values.isPointsOnly}
                                                disabled={false}
                                            />
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </section>

                    <section>
                        <div className="row-info">
                            <aside className={error(OfferValidators.contact).isValid ? "" : style.warn}>
                                <div><span>{++sectionIndex}</span></div>
                            </aside>
                            <div className="section-container">
                                <div className="row">
                                    <label>{localization["contactPrivacy"]}</label>
                                    <p>{localization["contactInfoDescription"]}</p>

                                    <ContactPrivacyOptions
                                        sharePrimaryContact={values.sharePrimaryContact}
                                        shareSecondaryContact={values.shareSecondaryContact}
                                        shareEmailContact={values.shareEmailContact}
                                        shareOtherContact={values.shareOtherContact}
                                        otherContactText={values.otherContact}
                                        onCheckChange={onContactCheckChange.bind(this)}
                                        onOtherTextChange={value => valueSetter({ name: "otherContact", value: value })}
                                    />
                                </div>
                                {
                                    !error(OfferValidators.contact).isValid &&
                                    <div>
                                        <InputWarning messageKey={error(OfferValidators.contact).message} />
                                    </div>
                                }
                            </div>
                        </div>
                    </section>

                    <section>
                        <div className="row-info">
                            <aside className={error(OfferValidators.location).isValid ? "" : style.warn}>
                                <div><span>{++sectionIndex}</span></div>
                            </aside>
                            <div className="section-container">
                                <div className="row">
                                    <label>{localization["Location"]}</label>
                                    <p style={{ marginBottom: "25px" }}>{localization["selectLocation"]}</p>
                                    {
                                        !profileHasLocation &&
                                        <div className={style.notification}>
                                            <p>Your profile does not have default location set. <Link to="/profile/edit">Manage</Link></p>
                                        </div>
                                    }
                                    {
                                        profileHasLocation &&
                                        <MultiRadio
                                            options={locationOptions}
                                            selectedOptionValue={locationOption?.value}
                                            onSelect={onSetLocationOption}
                                            group="location"
                                        />
                                    }

                                    {
                                        (locationOption?.value === 1 || !profileHasLocation) &&
                                        IsUndefinedOrNull(values.locationLongitude) &&
                                        IsUndefinedOrNull(values.locationLatitude) &&
                                        <div className={style.notification}>
                                            <p>{localization["mapSelectPoint"]}</p>
                                        </div>
                                    }

                                    {
                                        (locationOption?.value === 1 || !profileHasLocation) &&

                                        <GGLMap
                                            onClick={onMapClick}
                                            style={{ height: "300px" }}
                                            options={
                                                {
                                                    gestureHandling: "cooperative",
                                                    mapTypeControl: false,
                                                    streetViewControl: false,
                                                    fullscreenControl: false,
                                                    center: {
                                                        lng: IsUndefinedOrNull(values.locationLongitude) ? -72 : values.locationLongitude,
                                                        lat: IsUndefinedOrNull(values.locationLatitude) ? 45 : values.locationLatitude
                                                    },
                                                    zoom: mapZoom
                                                }
                                            }
                                        >
                                            {
                                                !IsUndefinedOrNull(values.locationLongitude) &&
                                                !IsUndefinedOrNull(values.locationLatitude) &&
                                                <GGLMarker
                                                    position={new google.maps.LatLng(values.locationLatitude, values.locationLongitude)} />
                                            }
                                        </GGLMap>
                                    }
                                </div>
                            </div>
                        </div>
                    </section>

                    <section style={{ overflow: "hidden" }}>
                        <div className="row-info">
                            <aside className={error(OfferValidators.description).isValid ? "" : style.warn}>
                                <div><span>{++sectionIndex}</span></div>
                            </aside>
                            <div className="section-container">
                                <div className="row">
                                    <label>{localization["offerDescription"]}</label>

                                    <div className={style.ckeditor}>
                                        <CKEditor

                                            data={values.description}
                                            config={CKConfig}
                                            editor={Editor}

                                            onChange={(event, editor) => {
                                                const data = editor.getData();
                                                valueSetter({ name: "description", value: data });
                                                if (!StringIsNullOrEmpty(exchangeid)) {
                                                    setIsDescriptionChanged(true);
                                                }
                                            }}

                                            onBlur={(event, editor) => {

                                            }}

                                            onFocus={(event, editor) => {

                                            }}
                                        />
                                    </div>

                                    {
                                        !StringIsNullOrEmpty(exchangeid) &&
                                        isDescriptionChanged &&
                                        <button onClick={saveDescription} className={classNames("btn", "save2", style.savechanges)}>
                                            <FontAwesomeIcon icon="floppy-disk" /><span>{localization["saveText"]}</span>
                                        </button>
                                    }
                                </div>

                                {
                                    !error(OfferValidators.description).isValid &&
                                    <div>
                                        <InputWarning messageKey={error(OfferValidators.description).message} />
                                    </div>
                                }
                            </div>

                        </div>
                    </section>

                    <section>
                        <div className="row-info">
                            <aside>
                                <div><span>{++sectionIndex}</span></div>
                            </aside>
                            <div className="section-container">
                                <div className="row">
                                    <label>{localization["webUrl"]}</label>
                                    <input
                                        type="text"
                                        placeholder={localization["webUrl"]}
                                        className="form-control"
                                        value={values.webUrl}
                                        onChange={(e) => valueSetter({ name: "webUrl", value: e.target.value })}
                                    />
                                </div>
                            </div>
                        </div>
                    </section>

                    {
                        !IsUndefinedOrNull(props.attachments) &&
                        props.attachments.length > 0 &&
                        <section>
                            <div className="row-info">
                                <aside className={style.imgaside}>
                                    <div><FontAwesomeIcon icon={"image"} /></div>
                                </aside>
                                <div className="section-container">
                                    <Thumbnails
                                        files={props.attachments.filter(x => x.attachmentType === AttachmentType.Image).map(p => p.fileId)} />

                                </div>
                            </div>
                        </section>
                    }


                </div>

                <section className={style.buttons}>
                    <div className={style.left}>
                        <button className={classNames(style.returnbtn, "btn", "action")} onClick={() => nav(-1)}>
                            <FontAwesomeIcon className="before" icon={"chevron-left"} />
                            <span>{localization["return"]}</span>
                        </button>
                    </div>

                    <div className={style.right}>

                        {
                            !IsUndefinedOrNull(props.state) &&
                            <button className={classNames(style.deletebtn, "btn", "cancel")} onClick={deleteExchange}>
                                <span>{localization["delete"]}</span>
                                <FontAwesomeIcon icon={"trash-can"} />
                            </button>
                        }
                        {
                            props.state !== ExchangeState.Published &&
                            isValid &&
                            <button className="btn save" onClick={publishOffer}>
                                <span>{localization["publish"]}</span>
                                <FontAwesomeIcon icon={"check"} />
                            </button>
                        }
                        {
                            props.state === ExchangeState.Published &&
                            <button className="btn warn" onClick={unpublishOffer}>
                                <span>{localization["unpublish"]}</span>
                                <FontAwesomeIcon icon={"circle-pause"} />
                            </button>
                        }
                        {
                            isDirty() &&
                            IsUndefinedOrNull(props.state) &&

                            <button
                                className="btn save2"
                                onClick={onSaveOfferDraft}>
                                <span>{localization["saveDraft"]}</span>
                                <FontAwesomeIcon icon={"floppy-disk"} />
                            </button>
                        }

                        {
                            isDirty() &&
                            !IsUndefinedOrNull(props.state) &&
                            props.state === ExchangeState.Published &&
                            isValid &&
                            <button
                                className="btn save2"
                                onClick={updatePublishedOffer}>
                                <span>{localization["save"]}</span>
                                <FontAwesomeIcon icon={"floppy-disk"} />
                            </button>
                        }
                        {
                            isDirty() &&
                            !IsUndefinedOrNull(props.state) &&
                            props.state === ExchangeState.Draft &&
                            <button
                                className="btn save2"
                                onClick={saveExistingDraftChanges}>
                                <span>{localization["save"]}</span>
                                <FontAwesomeIcon icon={"floppy-disk"} />
                            </button>
                        }
                        <button className={classNames(style.toimages, "btn", "action")} onClick={onGoToImages}>
                            <span>{localization["GoToImagesButton"]}</span>
                            <FontAwesomeIcon className={style.imgicon} icon="image" />
                            <FontAwesomeIcon icon={"plus"} />
                        </button>
                    </div>

                </section>

            </div >
        </section >
    );
}

export { ExchangeForm }