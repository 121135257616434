import { create } from "zustand";
import { SearchResultModel } from "../models/SearchResultModel";
import { SearchOptions } from "../models/SearchOptions";

export type HomeStore = {
    page: number;
    searchResults: Array<SearchResultModel>;
    searchHashCode: number;
    update?: <K extends keyof Omit<HomeStore, "update">>(key: K, value: HomeStore[K]) => void;
} & SearchOptions;

export const RadiusValues = [10, 20, 50, 100, 300, 500, 1000, 0];

const INITIAL_RADIUS = 3;

export const homeStore = create<HomeStore>((set) => ({
    page: 1,
    searchHashCode: 0,
    searchResults: [],
    orderBy: "date",
    locationIds: [],
    exchangeType: "*",
    categoryId: "*", 
    radiusControlPosition: INITIAL_RADIUS,
    queryText: "",
    includeResultsOffgidExchanges: false,
    includeMyExchanges: true,
    IsPointsOnly : false,
    update: (key, value) => set((state) => ({ [key]: value }))
}));
